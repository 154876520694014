import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import QRCode from "qrcode.react";
import html2canvas from "html2canvas";
import ToastComponent from "../Common/TaostComponent";
import ReactHtmlParser from "react-html-parser";
import CommonService from "../../services/common.service";

const BrandingDetailModal = (props) => {
  var brandingDetails = props.brandingData?.branding_data;
  const saveUserClick = (websiteLink, type, id) => {
    const data = {};
    data.type = type;
    data.type_id = id;
    CommonService.saveUserClick(data).then((res) => {
      //window.location.href = websiteLink;
      window.open(websiteLink, "_blank"); // Open the link in a new tab
    });
  };

  return (
    <>
      <>
        <Modal
          show={props.showBrandingModal}
          onHide={() => props.setShowBrandingModal(false)}
          size="lg"
          id="playermoreinfo"
        >
          <div className="gameprofile11 open11">
            <div className="gamevideo">
              <figure>
                <img src="/images/video-img.jpg" alt="img" />
              </figure>
            </div>
            <figure className="user-profile">
              {brandingDetails && (
                <img
                  src={`${process.env.REACT_APP_UPLOAD_URL}branding/${brandingDetails.logo}`}
                  alt="img"
                />
              )}
              {!brandingDetails && (
                <img src={`../images/small-brand-logo.jpg`} alt="img" />
              )}
            </figure>
            <div className="profile-content">
              <h4>{brandingDetails?.name}</h4>
              <div
                className="player-performance1"
                style={{ maxHeight: "500px", overflowY: "auto" }}
              >
                {ReactHtmlParser(brandingDetails?.about)}
              </div>

              <ul className="social-link no-border">
                {brandingDetails?.facebook && (
                  <li>
                    <Link
                      onClick={(e) =>
                        saveUserClick(
                          brandingDetails?.facebook,
                          "branding_facebook",
                          brandingDetails?.id
                        )
                      }
                    >
                      <img src="images/fb-icon.svg" alt="img" />
                    </Link>
                  </li>
                )}
                {brandingDetails?.twitter && (
                  <li>
                    <Link
                      onClick={(e) =>
                        saveUserClick(
                          brandingDetails?.twitter,
                          "branding_twitter",
                          brandingDetails?.id
                        )
                      }
                    >
                      <img src="images/twitter-icon.svg" alt="img" />
                    </Link>
                  </li>
                )}
                {brandingDetails?.instagram && (
                  <li>
                    <Link
                      onClick={(e) =>
                        saveUserClick(
                          brandingDetails?.instagram,
                          "branding_instagram",
                          brandingDetails?.id
                        )
                      }
                    >
                      <img src="images/insta-icon.svg" alt="img" />
                    </Link>
                  </li>
                )}
                {brandingDetails?.url && (
                  <li>
                    <Link
                      onClick={(e) =>
                        saveUserClick(
                          brandingDetails?.url,
                          "branding_website",
                          brandingDetails?.id
                        )
                      }
                    >
                      <img src="images/web-icon.svg" alt="img" />
                    </Link>
                  </li>
                )}
              </ul>
              <div className="">{props.clubDetails?.game_name}</div>
              <figure className="game-profile-img">
                {props.clubDetails && (
                  <img
                    src={`${process.env.REACT_APP_UPLOAD_URL}game_logo/${props.clubDetails?.logo}`}
                    alt="img"
                  />
                )}
                {!props.clubDetails && (
                  <img src={`../images/club-logo.png`} alt="img" />
                )}
              </figure>
            </div>
          </div>

          <Modal.Footer>
            <div className="poweredby">
              Powered By
              <img src="/images/logo.png" alt="img" />
            </div>

            <div className="hideClass">
              <Link
                className="border-btn"
                onClick={(e) => {
                  e.preventDefault();
                  props.setShowBrandingModal(false);
                }}
              >
                <i className="fas fa-times"></i>
              </Link>
            </div>
          </Modal.Footer>
        </Modal>
      </>
    </>
  );
};

export default BrandingDetailModal;
